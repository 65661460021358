<template>
    <div>
        <div class="filter-panel">
            <div class="cs-select" style="margin-right: 10px; width: 185px;">
                <el-date-picker
                    v-model="startDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <span
                style="
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 0 15px 0;
                    height: 2px;
                    width: 20px;
                    margin-right: 0;
                    background-color: #999;
                "
            >
            </span>
            <div class="cs-select" style="margin-left: 10px; width: 185px;">
                <el-date-picker
                    v-model="endDate"
                    type="date"
                    style="width: 157px;"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    :editable="false"
                ></el-date-picker>
                <i class="icon el-icon-caret-bottom"></i>
            </div>
            <input
                type="text"
                class="cs-input"
                style="margin-right: 30px;"
                placeholder="搜索用户信息"
                v-model="userInfo"
            />
            <button class="btn btn-primary" @click="getRefundLogs()">查询</button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel">累计已退金额: {{refundLogs.refundTotal || '-'}} 元</div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">退款时间</th>
                        <th class="text-center">用户信息</th>
                        <th class="text-center">会议室房间号</th>
                        <th class="text-center">单价</th>
                        <th class="text-center">实际使用时间</th>
                        <th class="text-center">已付金额</th>
                        <th class="text-center">已退金额</th>
                        <th class="text-center">预约使用时间</th>
                        <th class="text-center">用户评价内容</th>
                        <th class="text-center">退款人及原因</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="refundLogs.length > 0">
                        <tr v-for="(log, index) in refundLogs" :key="index">
                            <td class="text-center date-td">
                                {{ log.refundTime || '-' }}
                            </td>
                            <td class="text-center">
                                <div>
                                    <p style="margin-bottom: 5px;">{{ log.userName}} 【{{log.userPhone}}】</p>
                                    <p style="margin-bottom: 5px;">{{ log.userCompany }}</p>
                                    <p style="margin-bottom: 0;">{{ log.companyLocation }}</p>
                                </div>
                            </td>
                            <td class="text-center">
                                {{ log.buildingName + "-" + log.name }}
                            </td>
                            <td class="text-center">
                                {{ log.price ? log.price + '元' : '-' }}
                            </td>
                            <td class="text-center date-td">
                                {{ log.actualUseTime || '-' }}
                            </td>
                            <td class="text-center">
                                {{ log.payMoney ? log.payMoney + '元' : '-' }}
                            </td>
                            <td class="text-center">
                                {{ log.refundMoney ? log.refundMoney + '元' : '-' }}
                            </td>
                            <td class="text-center date-td">
                                {{ log.reservationTime || '-' }}
                            </td>
                            <td class="text-center">
                                <template v-if="log.evaluate">
                                    <span
                                        class="allow-click"
                                        @click="lookEvaluate(log)"
                                    >
                                        查看
                                    </span>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td class="text-center">
                                <div>
                                    <p style="margin-bottom: 0;">退款人: {{ log.refundName }}</p>
                                    <template v-if="log.refundCause">
                                        <p style="margin-bottom: 0; margin-top: 10px;">
                                            退款原因:
                                            <span
                                                class="allow-click"
                                                @click="
                                                    lookRefundReason(
                                                        log.refundCause
                                                    )
                                                "
                                            >
                                                查看
                                            </span>
                                        </p>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
            </CSTable>
        </div>
        <ViewModal></ViewModal>
        <Pagination></Pagination>
    </div>
</template>

<script>
import ViewModal from "@/components/ViewModal";
import { refundMeetingRoomUrl } from "@/requestUrl";
import Pagination from "@/components/Pagination.vue";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";
import CSTable from "@/components/common/CSTable";
export default {
    name: "RefundMeetingRoomCharge",
    created(){
        window.addEventListener("keydown",this.getRefundLogsDown);
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.getRefundLogsDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.getRefundLogsDown);
    },
    components: {
        CSTable,
        ViewModal,
        Pagination,
    },
    data() {
        return {
            startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
            userInfo: "",
            regionId: this.$vc.getCurrentRegion().communityId,
            filterHeight: 0,
            refundLogs: [],
        };
    },
    filters: {
        realMoney(val) {
            return val ? val / 100 + "元" : '-';
        },
    },
    methods: {
        getRefundLogsDown(e){
            if(e.keyCode == 13){
                this.getRefundLogs();
            }
        },
        //  获取退款记录
        getRefundLogs(pageNo = 1, pageSize = 10) {
            const { startDate, endDate, userInfo, regionId } = this;
            this.$fly
                .post(refundMeetingRoomUrl, {
                    startDate,
                    endDate,
                    userInfo,
                    regionId,
                    pageNo,
                    pageSize,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    if(pageNo === 1){
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total:res.data.total,
                            currentPage: pageNo,
                            pageSize,
                        });
                    }
                    this.refundLogs = res.data.datas || [];
                    this.refundLogs.refundTotal = res.data.refundTotal;
                });
        },
        /**
         * 查看用户评价
         * @param {Object} logInfo log信息
         *  */
        lookEvaluate(logInfo) {
            /*<div style="margin-bottom:30px">
                <span style="width:100px;margin-right: 30px;">评价星级</span>
                <div style="display:inline-block;">
                    ${logInfo.score}星
                </div>
            </div>*/
            this.$CSDialog.alert({
                title: '用户评价',
                messageHtml: `
                    <div style="font-size:24px;color: #000;display:block;" class="text-left">
                        <div style="margin-bottom:30px">
                            <span style="width:100px;margin-right: 30px;">评价时间</span>
                            <div style="display:inline-block;">
                            ${logInfo.evaluateTime}
                            </div>
                        </div>
                        <div>
                            <span style="width:100px;margin-right: 30px;vertical-align:top;">评价内容</span>
                            <div style="display:inline-block;vertical-align:top;width:380px;">
                            ${logInfo.evaluate}
                            </div>
                        </div>
                    </div>
                `
            })
        },
        /**
         * 查看退款原因
         * @param {String} reason
         *  */
        lookRefundReason(reason) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                content: reason,
            });
        },
    },
    mounted() {
        this.getRefundLogs();
        this.$vc.on(this.$route.path, "pagination", "page_event", (_currentPage) => {
            this.getRefundLogs(_currentPage);
        });
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
};
</script>

<style lang="stylus" scoped>
.filter-panel
    .cs-input
        border 1px solid #979797
        vertical-align middle
        width 240px
    .cs-select
        display inline-block
        border 1px solid #979797
        height 30px
        overflow hidden
        border-radius 4px
        vertical-align middle
        .icon
            width 26px
            text-align center
            border-left 1px solid #979797
            height 30px
            line-height 30px
            vertical-align middle
        .el-date-editor
            height 30px
            vertical-align middle
            input
                height 30px
                border 0
            .el-input__prefix, .el-input__suffix
                height 30px
                .el-input__icon
                    line-height 30px
.result-panel
    background #fff
    margin-top 20px
    padding 10px 0
    &-title
        padding 20px 30px
        margin-bottom 0
input::-webkit-input-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-moz-placeholder
    color: #999;
input::-ms-input-placeholder
    color: #999;
</style>
